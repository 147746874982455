import React, { useEffect, useState } from "react";
import Social from "./Social";
import Data from "./Data";
import Scroll from "./Scroll";

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        // Clean up the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // Import the correct CSS file based on screen size
        const loadStylesheet = async () => {
            if (isMobile) {
                await import('./home2.css');
            } else {
                await import('./home.css');
            }
        };

        loadStylesheet();
    }, [isMobile]);

    return (
        <section className="home section" id="home">
            <div className="home__container container grid">
                <div className="home__content grid">
                    <Social />
                    <div className="home__img"></div>
                    <Data />
                </div>
                <Scroll />
            </div> 
        </section>
    );
};

export default Home;
