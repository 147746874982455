import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import Portfolio from './components/projects/Portfolio';
import Preloader from './components/preloader/Preloader'; // Import Preloader component

const App = () => {
  const [loading, setLoading] = useState(true); // Loading state

  // Simulate loading duration
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust time as needed (in milliseconds)
  }, []);

  return (
    <>
      {loading ? (
        <Preloader /> // Show loading screen if loading
      ) : (
        <>
          <Header />
          <main className="main">
            <Home />
            <About />
            <Skills />
            <Services />
            <Qualification />
            <Portfolio />
            <Testimonials />
            <Contact />
          </main>
          <Footer />
          <ScrollUp />
        </>
      )}
    </>
  );
}

export default App;
