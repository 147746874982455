import Image1 from "../../assets/woman1.png";
import Image2 from "../../assets/man.png";
import Image3 from "../../assets/woman2.png";

export const Data = [
    {
        id: 1,
        image: Image2,
        title: "Sohan S Horta",
        description: "Working with Jason was great—excellent coding, clear communication, and a website that turned out perfect",
    },
    {
        id: 2,
        image: Image2,
        title: "Abhimanyu",
        description: "Jason created beautiful designs that matched exactly what I wanted.",
    },

];